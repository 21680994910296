import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { Menu } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { Button, Link, NavBar } from '@/atoms';

import ArrowDownPri from '@/assets/icons/ArrowDownPri.svg';
import IconoMenuMobile from '@/assets/icons/iconoMenuMobil.svg';
import LogoDesk from '@/assets/img/logo/logo-header.svg';
import { useRouteChangeStart } from '@/hooks/useRouteChangeStart';
import Mobile from './Mobile';

import type { TypesNavBar } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeHeaderProps } from './types';

const VARIANT_MAPS: Record<TypesNavBar, string> = {
  Default: 'fixed bg-[#ECE3DF] text-primary-400',
  Sticky:
    'fixed top-0 bg-gradient-to-b from-[#0000008a] text-white bg-transparent',
  StickyScrolled: 'fixed top-0 bg-[#ECE3DF] text-primary-400',
  Alternative: 'absolute bg-gradient-to-b from-[#0000008a] text-white',
};

/**
 * Header
 */
export const Header: FunctionComponent<TypeHeaderProps> = ({
  className = '',
  selectedLanguage,
  languagesToSelect,
  button,
  menuContent,
  type,
  mobilePolicies,
  socialMedia,
  landing = false,
}: TypeHeaderProps) => {
  const [open, setOpen] = useState(false);

  const mobileHandler = () => {
    setOpen(!open);
  };

  useRouteChangeStart(() => setOpen(false));

  const [tipe, setTipe] = useState(type);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (type === 'Sticky') {
        if (window.scrollY > 5) {
          setTipe('StickyScrolled');
        } else {
          if (isHovered) {
            setTipe('Default');
          } else {
            setTipe(type);
          }
        }
      }
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHovered, type]);

  return (
    <>
      <Mobile
        isOpen={open}
        menuContent={menuContent}
        policies={mobilePolicies}
        funccionOnClose={mobileHandler}
        selectedLanguage={selectedLanguage}
        languagesToSelect={languagesToSelect}
        socialMedia={socialMedia}
      />

      <header
        className={oneLine`
          max-md:items-center  z-50 flex h-auto w-full flex-row justify-between px-5 py-2 transition-colors  duration-0 md:h-16 md:justify-around md:py-0
          ${tipe === 'Sticky' ? '' : 'shadow-md'}
          ${VARIANT_MAPS[tipe]}
          ${className}
        `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex w-full max-w-[1440px] flex-row items-center  justify-between">
          <div>
            {!landing ? (
              <NextLink href="/" aria-label="Home" passHref prefetch={false}>
                <a aria-label="Home">
                  <LogoDesk className="h-12 w-8 min-w-[127px] sm:h-16 sm:w-14" />
                </a>
              </NextLink>
            ) : (
              <LogoDesk className="h-12 w-8 min-w-[127px] sm:h-16 sm:w-14" />
            )}
          </div>

          <NavBar
            lang={selectedLanguage}
            menuContent={menuContent}
            type={tipe}
            className="hidden md:flex md:h-full"
          />

          <div className="min-w-1/3 !text-body flex flex-row items-center gap-5 pl-3">
            {button?.src && button?.label && (
              <NextLink href={button.src} passHref prefetch={false}>
                <Button
                  className={`hidden h-fit font-bold tracking-[0.006em] transition-all !duration-0 md:block ${landing && 'whitespace-nowrap'}`}
                  as="a"
                  size="small"
                  variant={tipe === 'Sticky' ? 'secondary' : 'primary'}
                >
                  {button.label}
                </Button>
              </NextLink>
            )}

            {button?.src && button?.label && (
              <NextLink href={button.src} passHref prefetch={false}>
                <Link
                  className="block !text-[14px] !font-bold !tracking-[0.016px] md:hidden"
                  variant={tipe === 'Sticky' ? 'alternative' : 'primary'}
                >
                  {button.label}
                </Link>
              </NextLink>
            )}

            <IconoMenuMobile
              height={32}
              width={32}
              onClick={mobileHandler}
              className={oneLine`
              block cursor-pointer md:hidden
              ${tipe === 'Sticky' ? 'text-white' : 'text-primary-400'}
            `}
              role="button"
            />

            <Menu as="div" className="relative hidden flex-col gap-3 md:!flex">
              {({ open, close }) => (
                <>
                  <Menu.Button
                    disabled={languagesToSelect.length === 0}
                    className="text-body text-body--primary flex flex-row items-center gap-3 uppercase"
                  >
                    {selectedLanguage}
                    {languagesToSelect.length > 0 && (
                      <ArrowDownPri
                        height={16}
                        width={16}
                        className={`transition-all duration-0  ${open ? '-rotate-180' : ''
                          }`}
                      />
                    )}
                  </Menu.Button>

                  <Menu.Items
                    unmount={false}
                    className={oneLine`
                  absolute top-10 z-10 flex flex-col rounded-md p-1 shadow-md
                  ${tipe === 'Alternative'
                        ? 'bg-white text-black'
                        : 'bg-[#ECE3DF] text-primary-400'
                      }
                `}
                  >
                    {languagesToSelect.map((child) => (
                      <Menu.Item key={child.href}>
                        {/**
                         * DON'T REMOVE THIS
                         *
                         * This div protects the Link component from the Menu.Item that add a mouse event
                         * that makes the Link component to enter in legacy mode
                         **/}
                        <div>
                          <NextLink
                            href={child.href}
                            passHref
                            prefetch={false}
                            locale={child.locale}
                          >
                            <Link
                              variant={
                                tipe === 'Alternative'
                                  ? 'alternative'
                                  : 'primary'
                              }
                              className={`hover:[#ECE3DF] rounded-md p-2 uppercase ${tipe === 'Alternative' ? '!text-black' : ''
                                }`}
                              onClick={close}
                            >
                              {child.label}
                            </Link>
                          </NextLink>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </>
              )}
            </Menu>
          </div>
        </div>
      </header>
    </>
  );
};

Header.displayName = 'Header';
